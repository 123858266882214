/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Are you someone who lives in the state of Georgia and is starting feel the effects of gradual hearing loss? If so, you’re not alone. And if you’ve been living with hearing loss for awhile but haven’t sought assistance, you’re also in good company. All over the state, hundreds of thousands of people are trying to manage moderate to severe hearing loss instead of securing the kind of support and technology that can make a difference."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "And the benefits can be huge. Whether you work for one of Atlanta’s industrial giants or love following sports on TV, hearing loss can affect many aspects of your lifestyle. And how much of Georgia’s famous hospitality can you really enjoy without being able to hear your hosts properly? Poor hearing has effects right across our lives, from work to everyday socializing. So, let’s find out more about hearing loss in Georgia and try to suggest some ways to restore your hearing in the future."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "noise-sources-in-georgia",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#noise-sources-in-georgia",
    "aria-label": "noise sources in georgia permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Noise sources in Georgia"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "One thing that Georgians often fail to realize is just how easy it is to damage your hearing. Everyday life might seem harmless and routine, but even your daily work can slowly affect your hearing. In fact, occupational hearing loss is a massive problem across the nation."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The CDC reports that 22 million Americans are exposed to hazardous noise levels at work every day – and there are plenty of dangerous occupations in Georgia. From operating heavy machinery in warehouses of factories to staffing busy restaurants and manning the state’s thriving construction sector, plenty of jobs in the Peach State come with excess noise as part of the deal. But work isn’t the only danger."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Leisure is another source of dangerous noise exposure. Take hunting, for example. Recent studies have confirmed that hunters can sustain serious inner ear damage from just a few shots, while sustained exposure can cause severe problems in old age. With more than 600,000 hunters in the state, that’s a lot of people who could be risking their hearing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you add in music venues and sports crowds (where noise levels can match those of jet engines at times), as well as the constant assault on our ears from heavy traffic, you’ve got a recipe for hearing problems all over Georgia."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There’s nothing exceptional about the Peach State in this regard. But there’s no doubt that hearing loss is a major quality of life issue, and there’s also no doubt that most people can alleviate the condition if they take action."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-loss-in-georgia-a-look-at-the-numbers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-loss-in-georgia-a-look-at-the-numbers",
    "aria-label": "hearing loss in georgia a look at the numbers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing loss in Georgia: A look at the numbers"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To put things into perspective, let’s have a look at some statistics about hearing loss in Georgia. First off, about 15% of Georgians over the age of 18 report some form of hearing loss. With a population of over 10 million people, that adds up to approximately 1.5 million hearing loss sufferers – a huge chunk of the community."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With about a million seniors in the state, and hearing loss rates pushing 40-50% for those aged over 70, many of these sufferers are elderly. But there are still hundreds of thousands of adults in the state who take their hearing loss problems into work, with potentially damaging consequences."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "What’s most frustrating is that relatively few Americans seek help when hearing loss appears. Even among seniors over 70 years of age, just 30% of those who could benefit from hearing aids have tried them. As you might expect, the number for those under 70 is even lower, at just 16%."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we think that’s a big problem, because modern hearing aids can help millions of people improve their lives in a big way."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-hearcom-has-to-offer-georgia-residents",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-hearcom-has-to-offer-georgia-residents",
    "aria-label": "what hearcom has to offer georgia residents permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What hear.com has to offer Georgia residents"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you think that you could benefit from using hearing aids and want your hearing checked out, be sure to get in touch with hear.com. We operate a network across the state, with many Partner Providers located in most major towns."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "From Savannah to Macon and from Atlanta to Valdosta, if you need to see a specialist, one of our partners is close by. Our role is to connect patients and professionals with the latest hearing aid technology, and we supply cutting edge devices from the most innovative hearing companies in the world. All you need to do is sign up for a risk-free trial. Get in touch today to turn your hearing around."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If the TV is becoming more distant and music is becoming fuzzier, there’s no need to settle for hearing impairment. Wherever you are in Georgia, hear.com can supply technology to restore your hearing and get your life back on track."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Just fill out our questionnaire and our team of hearing experts will soon be in touch. After that, you can schedule an appointment at a local provider and start to experience the benefits of using the world’s most advanced hearing aids."), "\n", React.createElement(LandingPageCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
